body {
  letter-spacing: 0.09em;
//  font-size: 0.875rem;
  font-size: 0.920rem;
}

/* スクロールバー */
.scrollbar {
  width: 100%;
  height: 30px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.scrollbar .inner {
  height: 10px;
}
.scrollbarY {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.scrollbarY .inner {
  height: 90%;
}
.bottonStyle {
  background-color: transparent;
  font-size: 800%;
  font-weight:600;
  position: absolute;
  left: 92%;
  top: -1%;
  color:#FFFFFF;
}
/* スクロールバーのスタイル */
.scrollbar::-webkit-scrollbar,
.react-bootstrap-table::-webkit-scrollbar {
  height: 10px;
}
.scrollbar::-webkit-scrollbar-track,
.react-bootstrap-table::-webkit-scrollbar-track {
  border-radius: 5px;
}
.scrollbar::-webkit-scrollbar-thumb,
.react-bootstrap-table::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #e7e7e7;
}
/* スクロールバーのスタイル */
.scrollbarY::-webkit-scrollbar,
.react-bootstrap-table::-webkit-scrollbar {
  width: 10px;
}
.scrollbarY::-webkit-scrollbar-track,
.react-bootstrap-table::-webkit-scrollbar-track {
  border-radius: 5px;
}
.scrollbarY::-webkit-scrollbar-thumb,
.react-bootstrap-table::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #e7e7e7;
}
.button-like {
  border: 1px solid rgb(47, 53, 58);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.25rem;
}

.inline-spinner {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.cursor-pointer {
  cursor: pointer;
}

.cell-color-future {
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-future:hover {
  white-space: nowrap;
  cursor: pointer;
  background-color: #DADADA;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-future-event {
  white-space: nowrap;
  cursor: pointer;
  background-color: #F2F2FF;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-future-event:hover {
  white-space: nowrap;
  cursor: pointer;
  background-color: #DADAEA;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-past {
  white-space: nowrap;
  background-color: #EAEAEA;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.cell-color-past-event {
  white-space: nowrap;
  background-color: #EAEAFA;
  text-align: center;
  padding-right: 1px;
  padding-left: 1px;
}

.shift-cell {
  text-align: center;
}

p.list-empty {
  border: 1px #ccc solid;
  border-radius: 4px;
  padding: 2em;
  text-align: center;
  background-color: #f3f3f3;
  width: 92%;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

.masked-input {
  -webkit-text-security: disc;
}

ul.main-menu li {
  margin-top: 1em;
}

.form-navi {
  margin-top: 5em;
  text-align: center;
  background-color: #f4f4f4;
  padding: 1em;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.empty-data {
  text-align: center;
  color: #555;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 3em;
  border: 1px solid #ddd;
  border-radius: 6px;
}

p.version-info {
  color: #777;
  margin-top: 1em;
  text-align: right;
}

.app-landlord .sidebar {
  background: #80664a;
}
.app-landlord .nav .active {
  background: #a2886c;
}
.nav a:hover {
  background: #c2a88c;
}


.sidebar .nav {
  width: 310px;
  font-size: 0.875rem;
}

p.note {
  margin: 0;
  color: #575;
  font-size: 0.9em;
}

p.note ul {
  list-style: none;
}

p.note li {
  text-indent: -1.2rem;
}

p.note li::before {
  content: "※";
}
.modal-password-form {
  max-width: 700px;
}

.invalid-message {
  font-size: 90%;
  color: red;
  margin-bottom: 0;
}

.masked-input {
  -webkit-text-security: disc;
}

img.upload-preview-image {
  max-width: 100%;
  max-height: 100%;
  min-width: 120px;
  min-height: 90px;
}

.detail-view input.form-control[readonly],
.detail-view select.form-control[readonly]
{
  background-color: white;
  font-size: 100%;
  padding: 0;
  line-height: 22.08px;
  height: auto;
  color: rgb(35, 40, 44);
  border: none;
  -webkit-appearance: none;/* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
  appearance: none; /* 標準のスタイルを無効にする */
  white-space: normal;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: #ccc;
}
.shopCsvCreate .react-bootstrap-table {
  height: 80vh;
  overflow-y: scroll;
}

.shopCsvCreate .react-bootstrap-table table>thead>tr>th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: rgba(255, 255, 255, .6);
  z-index: 10;
}